<!-- eslint-disable max-len -->
<template>
  <svg
    width="280"
    height="185"
    viewBox="0 0 280 185"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_17_237)">
      <path
        d="M149.721 33.1758C158.977 26.3323 172.008 23.7088 182.204 29.0477C170.725 34.036 161.38 42.9273 155.82 54.1484C153.692 58.4838 151.776 63.5275 147.384 65.5311C144.652 66.7779 141.427 66.5372 138.598 65.5305C135.768 64.5237 133.251 62.8134 130.77 61.1212L130.025 61.0316C134.442 50.3961 140.465 40.0192 149.721 33.1758Z"
        fill="#40DDFF"
      />
      <path
        d="M182.122 29.2749C172.602 29.5735 163.418 32.8668 155.874 38.6871C154.228 39.9112 152.785 41.3874 151.598 43.0613C150.494 44.7293 149.857 46.6627 149.753 48.6605C149.618 50.5213 149.695 52.4403 149.111 54.2359C148.798 55.1506 148.295 55.9888 147.636 56.6955C146.977 57.4022 146.176 57.9614 145.286 58.3365C143.092 59.329 140.673 59.4732 138.302 59.532C135.669 59.5973 132.934 59.6234 130.54 60.864C130.25 61.0143 130.034 60.555 130.324 60.4049C134.489 58.2465 139.356 59.6547 143.734 58.3702C145.776 57.7707 147.651 56.5515 148.471 54.5117C149.188 52.7279 149.11 50.7489 149.231 48.8636C149.304 46.9132 149.853 45.0104 150.831 43.3216C151.929 41.5926 153.313 40.0632 154.923 38.7989C158.504 35.8948 162.519 33.5721 166.82 31.9162C171.719 29.9989 176.909 28.9346 182.166 28.7692C182.492 28.7591 182.445 29.2649 182.122 29.2749Z"
        fill="white"
      />
      <path
        d="M158.193 36.67C157.389 35.2707 157.108 33.6309 157.401 32.0435C157.694 30.4562 158.54 29.0246 159.79 28.0044C160.044 27.7989 160.375 28.1837 160.121 28.3895C158.955 29.3373 158.167 30.6709 157.897 32.1496C157.628 33.6283 157.895 35.1547 158.651 36.4533C158.815 36.7357 158.356 36.9508 158.193 36.67Z"
        fill="white"
      />
      <path
        d="M149.697 47.7079C152.872 48.5962 156.265 48.254 159.199 46.7497C159.489 46.6004 159.706 47.0596 159.415 47.2087C156.359 48.7685 152.828 49.1173 149.526 48.1858C149.211 48.0967 149.384 47.6194 149.697 47.7079Z"
        fill="white"
      />
      <path
        d="M171.694 30.6716C171.893 31.278 172.238 31.8255 172.7 32.265C173.162 32.7046 173.726 33.0224 174.341 33.19C174.657 33.275 174.484 33.7522 174.17 33.6678C173.491 33.4795 172.869 33.1277 172.358 32.6432C171.846 32.1587 171.461 31.5562 171.236 30.8883C171.209 30.8272 171.207 30.7582 171.23 30.6954C171.252 30.6327 171.298 30.5809 171.357 30.5505C171.418 30.5221 171.487 30.5189 171.551 30.5416C171.614 30.5643 171.666 30.611 171.694 30.6716Z"
        fill="white"
      />
      <path
        d="M190.288 67.1954C190.089 67.1479 189.891 67.1005 189.689 67.0558C187.015 66.4317 184.296 66.026 181.557 65.8428C181.346 65.8252 181.131 65.8107 180.919 65.7992C174.316 65.4281 167.701 66.2872 161.411 68.333C158.909 69.1494 156.473 70.1551 154.123 71.3414C150.878 72.98 147.49 75.0151 143.996 75.4716C143.632 75.5233 143.266 75.5524 142.898 75.5587L129.974 61.9474C129.96 61.9017 129.943 61.8588 129.929 61.813L129.388 61.2931C129.505 61.2235 129.628 61.1537 129.745 61.0841C129.812 61.0434 129.883 61.0058 129.95 60.9651C129.997 60.9389 130.043 60.9129 130.083 60.8866C130.098 60.8778 130.113 60.8692 130.126 60.8634C130.166 60.8372 130.206 60.817 130.243 60.7937C130.928 60.4021 131.617 60.0145 132.31 59.6312C132.313 59.6282 132.313 59.6282 132.319 59.6283C137.606 56.7205 143.183 54.229 148.96 52.6639C149.134 52.6168 149.311 52.5667 149.49 52.5259C152.098 51.8349 154.759 51.3584 157.444 51.1011C158.915 50.965 160.392 50.9035 161.869 50.9168C165.686 50.9587 169.472 51.6011 173.09 52.8207C180.3 55.2529 186.654 59.9662 190.034 66.6799C190.121 66.8517 190.204 67.0206 190.288 67.1954Z"
        fill="#40DDFF"
      />
      <path
        d="M190.086 67.3288C182.305 61.8303 172.991 58.9254 163.467 59.0268C161.417 59.0122 159.376 59.3212 157.422 59.9425C155.537 60.6092 153.866 61.7689 152.58 63.3011C151.353 64.7055 150.261 66.2844 148.714 67.3659C147.914 67.9077 147.009 68.2741 146.057 68.4412C145.106 68.6082 144.13 68.5721 143.194 68.3351C140.845 67.8055 138.826 66.4627 136.898 65.081C134.757 63.5468 132.557 61.9194 129.9 61.4673C129.578 61.4125 129.682 60.9156 130.003 60.9703C134.627 61.7569 137.666 65.8143 141.934 67.4266C143.926 68.179 146.156 68.3351 148.038 67.2007C149.683 66.2087 150.811 64.5815 152.041 63.1487C153.273 61.6357 154.857 60.4476 156.653 59.6882C158.57 58.9693 160.595 58.5821 162.641 58.5432C167.247 58.3821 171.849 58.9468 176.28 60.2167C181.344 61.6379 186.129 63.9159 190.426 66.9519C190.692 67.1399 190.35 67.5156 190.086 67.3288Z"
        fill="white"
      />
      <path
        d="M166.532 58.8135C166.732 57.2118 167.494 55.7334 168.683 54.6423C169.871 53.5512 171.409 52.9185 173.02 52.8571C173.346 52.8458 173.379 53.3525 173.052 53.3638C171.552 53.4182 170.12 54.0077 169.015 55.026C167.911 56.0443 167.206 57.4243 167.029 58.9169C166.99 59.2412 166.493 59.136 166.532 58.8135Z"
        fill="white"
      />
      <path
        d="M153.109 62.5069C155.11 65.1294 158.024 66.9008 161.272 67.4678C161.594 67.5237 161.49 68.0206 161.169 67.9647C157.79 67.3684 154.761 65.5188 152.685 62.7852C152.487 62.5245 152.912 62.2476 153.109 62.5069Z"
        fill="white"
      />
      <path
        d="M180.921 62.1605C180.714 62.7641 180.661 63.4096 180.765 64.0389C180.87 64.6683 181.129 65.2618 181.519 65.7662C181.72 66.0242 181.294 66.3009 181.095 66.0445C180.666 65.4852 180.381 64.8294 180.264 64.1343C180.147 63.4393 180.202 62.7261 180.424 62.0572C180.44 61.9924 180.479 61.936 180.535 61.8994C180.591 61.8628 180.658 61.8489 180.724 61.8604C180.79 61.8744 180.847 61.9138 180.884 61.97C180.921 62.0262 180.934 62.0947 180.921 62.1605Z"
        fill="white"
      />
      <path
        d="M147.279 43.3568H146.277V15.8945C146.277 13.8072 145.867 11.7403 145.069 9.81192C144.271 7.88352 143.101 6.13132 141.626 4.65539C140.152 3.17945 138.401 2.00867 136.474 1.2099C134.548 0.411127 132.483 1.12527e-06 130.397 0H72.2672C70.1818 -4.50089e-06 68.1168 0.411113 66.1902 1.20988C64.2635 2.00865 62.5129 3.17942 61.0383 4.65535C59.5637 6.13129 58.394 7.88348 57.5959 9.81188C56.7979 11.7403 56.3871 13.8071 56.3871 15.8944V166.555C56.3871 170.771 58.0602 174.813 61.0383 177.794C64.0163 180.775 68.0555 182.45 72.2672 182.45H130.397C134.609 182.45 138.648 180.775 141.626 177.794C144.604 174.813 146.277 170.771 146.277 166.555V62.905H147.279V43.3568Z"
        fill="#3F3D56"
      />
      <path
        d="M131.038 4.13527H123.45C123.799 4.99155 123.932 5.92058 123.838 6.84052C123.744 7.76046 123.425 8.64309 122.909 9.41063C122.394 10.1782 121.698 10.8071 120.883 11.242C120.067 11.6768 119.157 11.9043 118.234 11.9044H84.9316C84.0076 11.9043 83.0979 11.6768 82.2825 11.242C81.4671 10.8071 80.7711 10.1782 80.2557 9.41064C79.7403 8.64309 79.4215 7.76047 79.3272 6.84052C79.2329 5.92057 79.3661 4.99154 79.7151 4.13525H72.6279C69.4827 4.13525 66.4663 5.38582 64.2423 7.61184C62.0183 9.83785 60.7689 12.857 60.7689 16.005V166.445C60.7689 168.003 61.0756 169.547 61.6716 170.987C62.2676 172.427 63.1411 173.736 64.2423 174.838C65.3435 175.94 66.6508 176.814 68.0897 177.411C69.5285 178.007 71.0706 178.314 72.6279 178.314H131.038C132.595 178.314 134.137 178.007 135.576 177.411C137.015 176.814 138.322 175.94 139.424 174.838C140.525 173.736 141.398 172.427 141.994 170.987C142.59 169.547 142.897 168.003 142.897 166.445V16.005C142.897 12.857 141.648 9.83785 139.424 7.61184C137.2 5.38582 134.183 4.13526 131.038 4.13527Z"
        fill="white"
      />
      <path
        d="M102.955 129.987C98.654 129.991 94.3942 129.146 90.4196 127.501C86.4451 125.855 82.8337 123.441 79.7923 120.397C76.7508 117.353 74.339 113.738 72.6949 109.76C71.0507 105.782 70.2065 101.518 70.2105 97.213C70.2044 91.1677 71.8712 85.2389 75.0262 80.084C78.1813 74.9291 82.7011 70.7496 88.0846 68.009C91.9869 66.0218 96.2471 64.8365 100.614 64.5227C104.981 64.209 109.367 64.7733 113.513 66.1823C120.855 68.6867 127.071 73.7183 131.053 80.381C135.035 87.0437 136.526 94.9049 135.258 102.565C133.991 110.224 130.048 117.185 124.132 122.207C118.217 127.229 110.712 129.986 102.955 129.987ZM102.955 65.7237C97.9877 65.7091 93.0889 66.8852 88.6684 69.1537C84.8045 71.124 81.3892 73.8726 78.6371 77.2269C75.8849 80.5811 73.8553 84.4687 72.6756 88.6454C71.4959 92.8221 71.1916 97.1978 71.7818 101.498C72.372 105.798 73.8439 109.929 76.1052 113.633C78.3665 117.337 81.3684 120.532 84.9223 123.019C88.4762 125.507 92.5055 127.231 96.7571 128.086C101.009 128.94 105.391 128.906 109.629 127.985C113.866 127.063 117.868 125.275 121.382 122.732C123.081 121.505 124.653 120.11 126.072 118.567C130.224 114.071 132.975 108.461 133.99 102.424C135.005 96.3867 134.239 90.1843 131.785 84.5762C129.332 78.9682 125.298 74.1978 120.177 70.8494C115.057 67.5009 109.072 65.7197 102.955 65.7237Z"
        fill="#F0F0F0"
      />
      <path
        d="M32.0021 68.1266C32.9009 68.2394 33.7641 68.5481 34.5308 69.0309C35.2975 69.5137 35.9491 70.1589 36.4398 70.9211C36.9304 71.6832 37.2481 72.5438 37.3706 73.4421C37.493 74.3405 37.4171 75.2547 37.1483 76.1206L48.0278 85.1911L44.1332 93.1529L28.949 80.1276C27.5257 79.5792 26.3529 78.5273 25.6527 77.1712C24.9525 75.8151 24.7736 74.2491 25.1499 72.7698C25.5263 71.2905 26.4317 70.0008 27.6946 69.145C28.9575 68.2893 30.4901 67.9269 32.0021 68.1266Z"
        fill="#FFB6B6"
      />
      <path
        d="M133.611 105.953C133.393 103.605 132.491 99.3849 131.726 97.8244C129.373 91.6603 114.17 86.5941 112.128 85.941L111.61 80.1787L90.4866 77.9038L87.807 85.2727L80.2309 88.1174C79.7165 88.3094 79.251 88.613 78.8676 89.0063C78.4843 89.3996 78.1926 89.873 78.0136 90.3924L71.2164 92.3588L64.5091 94.2996L41.2068 77.8652L40.1066 78.585L32.7531 83.3876L31.6186 84.1288L61.7739 111.359L72.9756 108.729L81.3994 106.745L82.1184 109.667L80.2566 119.932C85.6508 125.333 92.7912 128.634 100.397 129.243C108.003 129.851 115.576 127.727 121.759 123.253L126.544 119.003C129.88 115.393 132.341 111.064 133.74 106.351L133.611 105.953Z"
        fill="#E4E4E4"
      />
      <path
        d="M107.685 73.1912C115.659 70.5487 119.984 61.9363 117.343 53.955C114.703 45.9737 106.099 41.6457 98.1246 44.2883C90.1505 46.9308 85.8264 55.5431 88.4666 63.5245C91.1067 71.5058 99.7113 75.8337 107.685 73.1912Z"
        fill="#FFB6B6"
      />
      <path
        d="M90.8916 41.3853C92.3702 39.2718 94.5891 37.7924 97.1074 37.2412C99.5973 36.7286 102.189 37.2044 104.335 38.5681C106.668 37.6272 109.185 37.0029 111.657 37.3203C114.129 37.6376 116.552 39.0197 117.695 41.2966C117.936 41.8864 118.228 42.454 118.568 42.9926C119.576 44.3383 121.448 44.5129 123.102 44.5775C122.266 45.0657 121.43 45.5538 120.593 46.0419C121.427 46.4307 122.261 46.8194 123.094 47.2082C121.96 47.6417 120.825 48.0751 119.691 48.5086C122.331 50.8317 122.97 54.966 121.97 58.4321C121.024 61.7138 118.853 64.4763 116.43 66.8414C115.957 65.0767 116.477 63.1634 116.824 61.3363C117.189 59.4058 117.248 57.1485 115.898 55.7855C114.987 54.8661 113.65 54.5884 112.39 54.3653C106.034 53.2402 99.6196 52.4778 93.1772 52.0819C92.3149 51.9627 91.4367 52.0524 90.6163 52.3436C89.0485 53.0409 88.4186 54.956 87.9642 56.654C87.7466 53.6735 87.9205 50.6773 88.4814 47.7421C88.9112 45.4956 89.5882 43.247 90.8916 41.3853Z"
        fill="#2F2E41"
      />
      <path
        class="animated-path"
        d="M110.66 145.429H92.6825C92.0581 145.429 91.4593 145.181 91.0178 144.739C90.5763 144.297 90.3282 143.698 90.3282 143.073C90.3282 142.448 90.5763 141.848 91.0178 141.406C91.4593 140.965 92.0581 140.716 92.6825 140.716H110.66C111.284 140.716 111.883 140.965 112.325 141.406C112.766 141.848 113.014 142.448 113.014 143.073C113.014 143.698 112.766 144.297 112.325 144.739C111.883 145.181 111.284 145.429 110.66 145.429Z"
        fill="#40DDFF"
      />
      <path
        class="animated-path"
        d="M51.6686 19.3328H2.2198C1.76413 19.3321 1.32736 19.1504 1.00541 18.8276C0.683452 18.5049 0.502625 18.0674 0.502625 17.6113C0.502625 17.1552 0.683452 16.7178 1.00541 16.3951C1.32736 16.0723 1.76413 15.8906 2.2198 15.8899H51.6686C52.1248 15.8899 52.5622 16.0713 52.8848 16.3941C53.2073 16.7169 53.3885 17.1548 53.3885 17.6113C53.3885 18.0679 53.2073 18.5057 52.8848 18.8286C52.5622 19.1514 52.1248 19.3328 51.6686 19.3328Z"
        fill="#40DDFF"
      />
      <path
        class="animated-path"
        d="M80.2188 30.3274H2.21978C1.76366 30.3274 1.32623 30.1461 1.00371 29.8233C0.681188 29.5005 0.5 29.0626 0.5 28.6061C0.5 28.1496 0.681188 27.7117 1.00371 27.3889C1.32623 27.0661 1.76366 26.8848 2.21978 26.8848H80.2188C80.6749 26.8848 81.1123 27.0661 81.4348 27.3889C81.7573 27.7117 81.9385 28.1496 81.9385 28.6061C81.9385 29.0626 81.7573 29.5005 81.4348 29.8233C81.1123 30.1461 80.6749 30.3274 80.2188 30.3274Z"
        fill="#40DDFF"
      />
      <path
        class="animated-path"
        d="M65.2298 40.6856H2.21978C1.76366 40.6856 1.32623 40.5042 1.00371 40.1814C0.681189 39.8586 0.5 39.4208 0.5 38.9643C0.5 38.5077 0.681189 38.0699 1.00371 37.7471C1.32623 37.4243 1.76366 37.2429 2.21978 37.2429H65.2298C65.686 37.2429 66.1234 37.4243 66.4459 37.7471C66.7684 38.0699 66.9496 38.5077 66.9496 38.9643C66.9496 39.4208 66.7684 39.8586 66.4459 40.1814C66.1234 40.5042 65.686 40.6856 65.2298 40.6856Z"
        fill="#CACACA"
      />
      <path
        class="animated-path"
        d="M37.7507 51.0057H2.21978C1.76366 51.0057 1.32623 50.8243 1.00371 50.5015C0.681189 50.1787 0.5 49.7408 0.5 49.2843C0.5 48.8278 0.681189 48.39 1.00371 48.0672C1.32623 47.7443 1.76366 47.563 2.21978 47.563H37.7507C38.2068 47.563 38.6442 47.7443 38.9668 48.0672C39.2893 48.39 39.4705 48.8278 39.4705 49.2843C39.4705 49.7408 39.2893 50.1787 38.9668 50.5015C38.6442 50.8243 38.2068 51.0057 37.7507 51.0057Z"
        fill="#CACACA"
      />
      <path
        class="animated-path"
        d="M57.3788 61.3247H2.21975C1.7641 61.324 1.32738 61.1424 1.00544 60.8196C0.683506 60.4969 0.502686 60.0595 0.502686 59.6034C0.502686 59.1474 0.683506 58.7099 1.00544 58.3872C1.32738 58.0645 1.7641 57.8828 2.21975 57.8821H57.3788C57.8344 57.8828 58.2711 58.0645 58.5931 58.3872C58.915 58.7099 59.0958 59.1474 59.0958 59.6034C59.0958 60.0595 58.915 60.4969 58.5931 60.8196C58.2711 61.1424 57.8344 61.324 57.3788 61.3247Z"
        fill="#CACACA"
      />
      <path
        d="M251.195 178.538L246.763 178.537L244.654 161.427L251.196 161.427L251.195 178.538Z"
        fill="#9F616A"
      />
      <path
        d="M252.938 184.677L236.018 184.676V184.462C236.018 182.714 236.712 181.037 237.947 179.801C239.182 178.565 240.857 177.87 242.604 177.87H242.604L245.695 175.523L251.461 177.871L252.939 177.871L252.938 184.677Z"
        fill="#2F2E41"
      />
      <path
        d="M248.612 98.1226L246.658 74.2683L245.122 65.3148L253.719 63.4143L256.572 75.6722L254.087 98.7845C254.588 99.3995 254.894 100.15 254.966 100.941C255.038 101.731 254.872 102.525 254.489 103.221C254.107 103.916 253.525 104.481 252.82 104.843C252.114 105.205 251.316 105.348 250.529 105.252C249.742 105.157 249.001 104.828 248.401 104.309C247.802 103.789 247.372 103.102 247.166 102.335C246.96 101.569 246.987 100.758 247.245 100.007C247.503 99.2567 247.979 98.6003 248.612 98.1226Z"
        fill="#9F616A"
      />
      <path
        d="M227.653 178.538L223.221 178.537L221.112 161.427L227.653 161.427L227.653 178.538Z"
        fill="#9F616A"
      />
      <path
        d="M220.56 103.53L218.595 106.932C218.595 106.932 216.436 107.522 217.142 114.566C217.847 121.61 217.878 163.342 221.293 168.477L221.911 172.887L228.36 173.504L228.523 169.591L236.212 115.231C236.212 115.231 240.741 168.595 244.488 169.901L245.841 174.347L251.751 174.349L251.402 169.11L251.783 102.979L220.56 103.53Z"
        fill="#2F2E41"
      />
      <path
        d="M218.4 106.673L218.264 106.638C222.319 91.4558 221.149 71.7968 218.286 50.6867L218.286 50.5993L228.637 41.1094H237.626L246.537 46.8652C252.298 49.7481 253.607 55.8532 253.619 55.9147L253.628 55.9581L250.099 68.2899L252.758 103.953L252.761 103.995L252.746 104.033C251.885 106.217 249.579 105.906 246.909 105.546C244.243 105.186 234.42 108.476 231.408 108.476C225.522 108.476 218.82 106.781 218.4 106.673Z"
        fill="#E4E4E4"
      />
      <path
        d="M244.671 51.6716L248.533 49.9902L253.62 56.2336L257.744 76.24L243.619 74.0979L244.671 51.6716Z"
        fill="#E4E4E4"
      />
      <path
        d="M185.277 88.5638L202.929 72.4184L209.065 65.7236L215.828 71.3663L207.888 81.1262L188.104 93.3025C187.925 94.0758 187.519 94.7777 186.938 95.3182C186.357 95.8587 185.629 96.2131 184.845 96.3359C184.062 96.4586 183.259 96.3441 182.541 96.007C181.823 95.6699 181.223 95.1257 180.816 94.4442C180.409 93.7628 180.216 92.9752 180.26 92.1827C180.304 91.3902 180.584 90.629 181.063 89.9968C181.543 89.3646 182.2 88.8904 182.951 88.6351C183.702 88.3797 184.512 88.3549 185.277 88.5638Z"
        fill="#9F616A"
      />
      <path
        d="M202.545 72.594L207.434 66.2391C207.475 66.1479 211.531 57.0751 215.047 53.133C218.654 49.0891 221.494 51.1571 221.522 51.1794L221.593 51.2327L221.789 66.1661L208.251 82.3571L202.545 72.594Z"
        fill="#E4E4E4"
      />
      <path
        d="M240.374 32.1704C241.103 27.6743 238.052 23.4379 233.56 22.7083C229.068 21.9787 224.835 25.0321 224.107 29.5282C223.378 34.0243 226.428 38.2607 230.92 38.9903C235.412 39.7199 239.645 36.6665 240.374 32.1704Z"
        fill="#9F616A"
      />
      <path
        d="M224.707 29.625C224.707 29.625 224.641 26.7256 224.275 25.4426C223.909 24.1597 223.176 23.06 223.542 23.06C223.909 23.06 224.092 22.1436 224.092 22.1436C224.092 22.1436 224.458 21.9603 224.641 22.1436C224.824 22.3269 225.007 20.8607 225.007 20.8607C225.007 20.8607 225.19 20.3108 225.374 20.6774C225.557 21.044 225.557 20.8607 225.74 20.3108C225.923 19.761 226.838 19.3944 226.838 19.3944C226.838 19.3944 230.318 19.5777 230.867 18.8446C231.416 18.1115 237.642 18.6613 238.924 19.5777C240.206 20.4941 245.882 23.7931 244.967 26.5423C244.051 29.2914 241.415 35.6213 240.755 36.256C239.711 37.2595 238.988 37.6214 234.046 38.6056C233.079 38.7982 228.142 35.6383 228.142 35.0885C228.142 34.5387 228.737 32.795 227.781 32.5557C226.335 32.1938 226.172 34.0236 225.806 33.4738C225.44 32.924 224.707 29.625 224.707 29.625Z"
        fill="#2F2E41"
      />
      <path
        d="M229.396 184.677L212.476 184.676V184.462C212.476 182.714 213.17 181.037 214.405 179.801C215.64 178.565 217.315 177.87 219.062 177.87H219.062L222.153 175.523L227.919 177.871L229.397 177.871L229.396 184.677Z"
        fill="#2F2E41"
      />
      <path
        d="M278.99 185H1.92451C1.78934 185 1.65971 184.946 1.56414 184.851C1.46856 184.755 1.41486 184.625 1.41486 184.49C1.41486 184.355 1.46856 184.225 1.56414 184.129C1.65971 184.033 1.78934 183.98 1.92451 183.98H278.99C279.126 183.98 279.255 184.033 279.351 184.129C279.446 184.225 279.5 184.355 279.5 184.49C279.5 184.625 279.446 184.755 279.351 184.851C279.255 184.946 279.126 185 278.99 185Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_17_237">
        <rect width="279" height="185" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>

<style lang="scss" script>
@keyframes bounce {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-5px);
    opacity: 1;
  }
}
.animated-path {
  animation: bounce 1.3s infinite ease-in-out;
}
</style>
