<!-- eslint-disable max-len -->
<template>
  <svg width="37" height="30" viewBox="0 0 37 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.68624 29.9999H0.434295C0.319158 29.9998 0.208774 29.9541 0.127356 29.873C0.0459391 29.7918 0.000137561 29.6818 0 29.567V15.6008C0.000137561 15.486 0.0459391 15.376 0.127356 15.2948C0.208774 15.2137 0.319158 15.168 0.434295 15.1679H4.68624C4.80138 15.168 4.91176 15.2137 4.99318 15.2948C5.0746 15.376 5.1204 15.486 5.12054 15.6008V29.567C5.1204 29.6818 5.0746 29.7918 4.99318 29.873C4.91176 29.9541 4.80138 29.9998 4.68624 29.9999Z"
      fill="#40DDFF"
    />
    <path
      d="M12.4402 29.9999H8.18825C8.07312 29.9998 7.96273 29.9541 7.88131 29.873C7.7999 29.7918 7.75409 29.6818 7.75396 29.567V10.0587C7.75409 9.9439 7.7999 9.83387 7.88131 9.75271C7.96273 9.67155 8.07312 9.6259 8.18825 9.62577H12.4402C12.5553 9.6259 12.6657 9.67155 12.7471 9.75271C12.8286 9.83387 12.8744 9.9439 12.8745 10.0587V29.567C12.8744 29.6818 12.8286 29.7918 12.7471 29.873C12.6657 29.9541 12.5553 29.9998 12.4402 29.9999Z"
      fill="#40DDFF"
    />
    <path
      d="M20.1942 29.9999H15.9422C15.8271 29.9998 15.7167 29.9541 15.6353 29.873C15.5539 29.7918 15.5081 29.6818 15.5079 29.567V15.6008C15.5081 15.486 15.5539 15.376 15.6353 15.2948C15.7167 15.2137 15.8271 15.168 15.9422 15.1679H20.1942C20.3093 15.168 20.4197 15.2137 20.5011 15.2948C20.5825 15.376 20.6283 15.486 20.6285 15.6008V29.567C20.6283 29.6818 20.5825 29.7918 20.5011 29.873C20.4197 29.9541 20.3093 29.9998 20.1942 29.9999Z"
      fill="#40DDFF"
    />
    <path
      d="M27.9481 29.9999H23.6962C23.5837 30.0023 23.4748 29.9602 23.3934 29.8827C23.312 29.8052 23.2647 29.6988 23.2619 29.5866V7.85139C23.2647 7.73924 23.312 7.63276 23.3934 7.5553C23.4748 7.47783 23.5837 7.43568 23.6962 7.4381H27.9481C28.0606 7.43568 28.1695 7.47783 28.2509 7.5553C28.3323 7.63276 28.3796 7.73924 28.3824 7.85139V29.5866C28.3796 29.6988 28.3323 29.8052 28.2509 29.8827C28.1695 29.9602 28.0606 30.0023 27.9481 29.9999Z"
      fill="#40DDFF"
    />
    <path
      d="M35.7021 29.9999H31.4501C31.335 29.9998 31.2246 29.9541 31.1432 29.873C31.0618 29.7918 31.016 29.6818 31.0158 29.567V3.93319C31.016 3.81841 31.0618 3.70837 31.1432 3.62721C31.2246 3.54606 31.335 3.50041 31.4501 3.50028H35.7021C35.8172 3.50041 35.9276 3.54606 36.009 3.62721C36.0904 3.70837 36.1362 3.81841 36.1364 3.93319V29.567C36.1362 29.6818 36.0904 29.7918 36.009 29.873C35.9276 29.9541 35.8172 29.9998 35.7021 29.9999Z"
      fill="#40DDFF"
    />
    <path
      d="M2.56027 13.4177C3.04507 13.4177 3.43808 13.026 3.43808 12.5427C3.43808 12.0594 3.04507 11.6676 2.56027 11.6676C2.07547 11.6676 1.68246 12.0594 1.68246 12.5427C1.68246 13.026 2.07547 13.4177 2.56027 13.4177Z"
      fill="#E6E6E6"
    />
    <path
      d="M10.3142 7.72979C10.799 7.72979 11.192 7.33801 11.192 6.85472C11.192 6.37143 10.799 5.97965 10.3142 5.97965C9.82943 5.97965 9.43642 6.37143 9.43642 6.85472C9.43642 7.33801 9.82943 7.72979 10.3142 7.72979Z"
      fill="#E6E6E6"
    />
    <path
      d="M18.0682 13.4177C18.553 13.4177 18.946 13.026 18.946 12.5427C18.946 12.0594 18.553 11.6676 18.0682 11.6676C17.5834 11.6676 17.1904 12.0594 17.1904 12.5427C17.1904 13.026 17.5834 13.4177 18.0682 13.4177Z"
      fill="#E6E6E6"
    />
    <path
      d="M25.8221 4.95873C26.3069 4.95873 26.6999 4.56695 26.6999 4.08366C26.6999 3.60037 26.3069 3.20859 25.8221 3.20859C25.3373 3.20859 24.9443 3.60037 24.9443 4.08366C24.9443 4.56695 25.3373 4.95873 25.8221 4.95873Z"
      fill="#E6E6E6"
    />
    <path
      d="M33.5761 1.75014C34.0609 1.75014 34.4539 1.35836 34.4539 0.875071C34.4539 0.391782 34.0609 0 33.5761 0C33.0913 0 32.6983 0.391782 32.6983 0.875071C32.6983 1.35836 33.0913 1.75014 33.5761 1.75014Z"
      fill="#E6E6E6"
    />
    <path
      d="M2.51345 12.5974L2.43169 12.4879L10.3143 6.6377L18.0431 12.3847L25.8778 3.90491L25.8918 3.89918L33.4953 0.770811L33.5475 0.897026L25.958 4.01959L18.0602 12.5677L10.3142 6.80794L2.51345 12.5974Z"
      fill="#E6E6E6"
    />
  </svg>
</template>
