<!-- eslint-disable max-len -->
<template>
  <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99403 27.3606C6.41337 27.3606 5.85998 27.1212 5.46183 26.6941C2.8985 23.9437 1.48682 20.3499 1.48682 16.5747C1.48682 13.4287 2.45381 10.4251 4.28332 7.88857C4.64486 7.38744 5.20585 7.07293 5.82254 7.0258C6.44891 6.97708 7.06163 7.20866 7.50327 7.65898L14.9367 15.2372C15.6806 15.9956 15.7566 17.2064 15.1136 18.0536L8.68972 26.5162C8.30801 27.0191 7.72934 27.3258 7.10198 27.3579C7.06594 27.3597 7.0299 27.3606 6.99403 27.3606Z"
      fill="#40DDFF"
    />
    <path
      d="M18.838 31.7805C16.546 31.7805 14.3468 31.2677 12.3014 30.2566C11.5584 29.8894 11.0237 29.2125 10.8343 28.3993C10.645 27.5861 10.8251 26.7397 11.3284 26.077L16.6815 19.0247C17.6554 17.7418 17.5401 15.9081 16.4136 14.7595L10.9337 9.17294C10.1416 8.3654 9.78237 7.25787 9.94834 6.13432C10.1151 5.00494 10.7832 4.04565 11.7812 3.50256C14.0316 2.27799 16.5815 1.65824 19.1557 1.71396C26.9136 1.87731 33.3082 8.12489 33.7136 15.9371C33.9302 20.1108 32.4853 24.0728 29.6451 27.0932C26.8032 30.1158 22.9651 31.7805 18.838 31.7805ZM18.8435 2.22318C16.4596 2.22318 17.03 4.50548 14.9446 5.64021C14.0887 6.10588 10.5936 5.24178 10.4506 6.2099C10.3085 7.17211 10.6161 8.12072 11.2946 8.81238L16.7745 14.3989C18.0786 15.7286 18.212 17.8512 17.0847 19.3363L11.7315 26.3886C11.3211 26.929 11.1744 27.6191 11.3288 28.2822C11.4831 28.9451 11.919 29.497 12.5247 29.7965C14.5 30.7729 16.6241 27.3383 18.838 27.3383C22.8244 27.3383 26.5316 29.66 29.2768 26.7405C32.0202 23.8229 33.4157 19.9957 33.2064 15.9639C32.8149 8.41846 26.6384 2.38419 19.1452 2.22635C19.0446 2.22426 18.9439 2.22318 18.8435 2.22318Z"
      fill="#E6E6E6"
    />
    <path
      d="M19.8143 16.8076C19.5249 16.4662 19.3801 15.9666 19.3801 15.3084C19.3801 14.6565 19.5249 14.1597 19.8143 13.8184C20.1037 13.4771 20.5045 13.3064 21.0164 13.3064C21.5343 13.3064 21.9397 13.4771 22.2322 13.8184C22.5247 14.1597 22.6709 14.6565 22.6709 15.3084C22.6709 15.9666 22.5247 16.4662 22.2322 16.8076C21.9397 17.149 21.5343 17.3196 21.0164 17.3196C20.5045 17.3196 20.1037 17.149 19.8143 16.8076ZM21.5375 16.2771C21.6471 16.0803 21.7019 15.7575 21.7019 15.3084C21.7019 14.8718 21.6456 14.5535 21.5328 14.3535C21.42 14.1537 21.2479 14.0537 21.0164 14.0537C20.7909 14.0537 20.6234 14.1537 20.5136 14.3535C20.4039 14.5535 20.3491 14.8718 20.3491 15.3084C20.3491 15.7512 20.4039 16.0727 20.5136 16.2725C20.6234 16.4725 20.7909 16.5723 21.0164 16.5723C21.254 16.5723 21.4278 16.4741 21.5375 16.2771ZM21.456 19.0891L24.7186 13.3986C25.169 13.3986 25.4501 13.8911 25.2241 14.2844L21.9579 19.9675C21.5113 19.9675 21.2323 19.4793 21.456 19.0891ZM24.4488 19.52C24.1594 19.1787 24.0147 18.6759 24.0147 18.0116C24.0147 17.3597 24.1594 16.8646 24.4488 16.5262C24.7382 16.188 25.142 16.0188 25.6601 16.0188C26.1779 16.0188 26.5818 16.188 26.8712 16.5262C27.1606 16.8646 27.3054 17.3628 27.3054 18.0208C27.3054 18.679 27.1606 19.1787 26.8712 19.52C26.5818 19.8614 26.1779 20.0321 25.6601 20.0321C25.142 20.0321 24.7382 19.8614 24.4488 19.52ZM26.172 18.9849C26.2817 18.7851 26.3365 18.4637 26.3365 18.0208C26.3365 17.5842 26.2801 17.2659 26.1674 17.066C26.0545 16.8662 25.8855 16.7661 25.6601 16.7661C25.4284 16.7661 25.2579 16.8662 25.1482 17.066C25.0384 17.2659 24.9836 17.5842 24.9836 18.0208C24.9836 18.4637 25.0384 18.7851 25.1482 18.9849C25.2579 19.1849 25.4284 19.2848 25.6601 19.2848C25.8916 19.2848 26.0623 19.1849 26.172 18.9849Z"
      fill="#E6E6E6"
    />
  </svg>
</template>
