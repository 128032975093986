<!-- eslint-disable max-len -->
<template>
  <svg class="wave" viewBox="0 0 390 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_42_378)">
      <path
        class="wave-path"
        d="M0 15L7.5 19.5C15.875 23.5 27.75 28.7383 44 29.5C60.25 30.2617 81.25 20.1758 97.5 16.0625C113.75 11.9492 130 15.3008 146.25 18.5C162.5 21.6992 178.75 25.0508 195 22.5676C211.25 20.1758 227.5 11.9492 243.75 11.1875C260 10.4258 276.25 16.8242 292.5 22.5676C308.75 28.25 324.068 28.5 340.5 28.5C360.5 28.5 377 23.5 384.5 21.5L390 20V-1H381.875C373.75 -1 357.5 -1 341.25 -1C325 -1 308.75 -1 292.5 -1C276.25 -1 260 -1 243.75 -1C227.5 -1 211.25 -1 195 -1C178.75 -1 162.5 -1 146.25 -1C130 -1 113.75 -1 97.5 -1C81.25 -1 65 -1 48.75 -1C32.5 -1 16.25 -1 8.125 -1H0V15Z"
        fill="#16151A"
      />
    </g>
    <defs>
      <clipPath id="clip0_42_378">
        <rect width="390" height="39" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped>
.wave {
  height: auto;
}
.wave-path {
  transform: scale(1, 0.5);
}
</style>
