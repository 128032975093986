<!-- eslint-disable max-len -->
<template>
  <svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.063 33L28.0238 32.9965C20.7312 31.7103 12.63 31.7108 3.25729 32.998H3.25354C3.01971 33.001 2.78767 32.9544 2.57074 32.8612C2.35382 32.7679 2.15632 32.6297 1.98963 32.4545C1.82295 32.2793 1.69038 32.0707 1.59955 31.8405C1.50872 31.6104 1.46142 31.3633 1.46041 31.1135V22.0475C1.46096 21.5474 1.64722 21.0678 1.97834 20.7142C2.30945 20.3605 2.75839 20.1616 3.22666 20.161C11.4804 19.0116 19.839 19.0122 28.0926 20.163H28.0959C28.5588 20.1703 29.0004 20.3723 29.3249 20.725C29.6494 21.0778 29.8306 21.553 29.8293 22.0475V31.1135C29.8287 31.6137 29.6425 32.0932 29.3113 32.4468C28.9802 32.8005 28.5313 32.9994 28.063 33Z"
      fill="#252525"
    />
    <path
      d="M28.063 33L28.0238 32.9965C20.7312 31.7103 12.63 31.7108 3.25729 32.998H3.25354C3.01971 33.001 2.78767 32.9544 2.57074 32.8612C2.35382 32.7679 2.15632 32.6297 1.98963 32.4545C1.82295 32.2793 1.69038 32.0707 1.59955 31.8405C1.50872 31.6104 1.46142 31.3633 1.46041 31.1135V22.0475C1.46096 21.5474 1.64722 21.0678 1.97834 20.7142C2.30945 20.3605 2.75839 20.1616 3.22666 20.161C11.4804 19.0116 19.839 19.0122 28.0926 20.163H28.0959C28.5588 20.1703 29.0004 20.3723 29.3249 20.725C29.6494 21.0778 29.8306 21.553 29.8293 22.0475V31.1135C29.8287 31.6137 29.6425 32.0932 29.3113 32.4468C28.9802 32.8005 28.5313 32.9994 28.063 33ZM15.3295 19.8142C11.2926 19.8065 7.25982 20.0931 3.25941 20.6721C3.08811 20.6692 2.91796 20.7025 2.7588 20.7702C2.59964 20.8379 2.45461 20.9386 2.33206 21.0665C2.20951 21.1943 2.11188 21.3469 2.04481 21.5152C1.97773 21.6836 1.94253 21.8645 1.94124 22.0475V31.1135C1.94162 31.4775 2.07717 31.8265 2.31815 32.0839C2.55913 32.3413 2.88586 32.486 3.22666 32.4864C12.6074 31.196 20.7477 31.1958 28.0819 32.4862C28.4194 32.4805 28.7413 32.3334 28.9782 32.0767C29.2151 31.8199 29.348 31.474 29.3484 31.1135V22.0475C29.348 21.6835 29.2125 21.3345 28.9715 21.0772C28.7305 20.8198 28.4038 20.675 28.063 20.6746C23.8391 20.1087 19.5865 19.8214 15.3295 19.8142Z"
      fill="#40DDFF"
    />
    <path
      d="M15.6448 29.7426C17.5037 29.7426 19.0106 28.1331 19.0106 26.1477C19.0106 24.1623 17.5037 22.5528 15.6448 22.5528C13.7859 22.5528 12.279 24.1623 12.279 26.1477C12.279 28.1331 13.7859 29.7426 15.6448 29.7426Z"
      fill="#40DDFF"
    />
    <path
      d="M6.98993 24.2695H4.10496C3.91374 24.2692 3.73041 24.188 3.5952 24.0436C3.45999 23.8992 3.38393 23.7034 3.38372 23.4991V22.472C3.38393 22.2678 3.45999 22.072 3.5952 21.9275C3.73041 21.7831 3.91374 21.7019 4.10496 21.7017H6.98993C7.18115 21.7019 7.36448 21.7831 7.49969 21.9275C7.63491 22.072 7.71096 22.2678 7.71117 22.472V23.4991C7.71096 23.7034 7.63491 23.8992 7.49969 24.0436C7.36448 24.188 7.18115 24.2692 6.98993 24.2695Z"
      fill="#40DDFF"
    />
    <path
      d="M27.1847 31.4593H24.2997C24.1085 31.4591 23.9252 31.3778 23.79 31.2334C23.6547 31.089 23.5787 30.8932 23.5785 30.6889V29.6618C23.5787 29.4576 23.6547 29.2618 23.79 29.1174C23.9252 28.9729 24.1085 28.8917 24.2997 28.8915H27.1847C27.3759 28.8917 27.5592 28.9729 27.6944 29.1174C27.8297 29.2618 27.9057 29.4576 27.9059 29.6618V30.6889C27.9057 30.8932 27.8297 31.089 27.6944 31.2334C27.5592 31.3778 27.3759 31.4591 27.1847 31.4593Z"
      fill="#40DDFF"
    />
    <path
      d="M23.9582 29.2503L23.9234 29.2308C17.6731 25.0168 10.2094 21.6524 1.10581 18.9451L1.10235 18.9436C0.885847 18.8492 0.689005 18.71 0.523117 18.5339C0.35723 18.3579 0.225602 18.1485 0.13582 17.9179C0.0460382 17.6873 -0.000122161 17.44 2.42796e-07 17.1902C0.000122646 16.9404 0.0465166 16.6932 0.136524 16.4627L3.43749 8.11027C3.6201 7.64972 3.96629 7.28532 4.40011 7.09704C4.83393 6.90875 5.31997 6.91194 5.75159 7.10591C13.7742 9.47523 21.4745 12.9477 28.6594 17.4361L28.6625 17.4375C29.0863 17.6365 29.4196 18.006 29.5901 18.4658C29.7606 18.9255 29.7545 19.4386 29.5732 19.8936L26.2723 28.246C26.0897 28.7065 25.7435 29.0709 25.3096 29.2592C24.8758 29.4475 24.3898 29.4443 23.9582 29.2503Z"
      fill="#252525"
    />
    <path
      d="M23.9582 29.2503L23.9234 29.2308C17.6731 25.0168 10.2094 21.6524 1.10581 18.9451L1.10235 18.9436C0.885847 18.8492 0.689005 18.71 0.523117 18.5339C0.35723 18.3579 0.225602 18.1485 0.13582 17.9179C0.0460382 17.6873 -0.000122161 17.44 2.42796e-07 17.1902C0.000122646 16.9404 0.0465166 16.6932 0.136524 16.4627L3.43749 8.11027C3.6201 7.64972 3.96629 7.28532 4.40011 7.09704C4.83393 6.90875 5.31997 6.91194 5.75159 7.10591C13.7742 9.47523 21.4745 12.9477 28.6594 17.4361L28.6625 17.4375C29.0863 17.6365 29.4196 18.006 29.5901 18.4658C29.7606 18.9255 29.7545 19.4386 29.5732 19.8936L26.2723 28.246C26.0897 28.7065 25.7435 29.0709 25.3096 29.2592C24.8758 29.4475 24.3898 29.4443 23.9582 29.2503ZM17.028 11.8134C13.3116 10.1295 9.49197 8.71854 5.59566 7.5903C5.4389 7.51648 5.27001 7.47655 5.09873 7.4728C4.92744 7.46906 4.75716 7.50158 4.59771 7.56848C4.43825 7.63539 4.29278 7.73535 4.16968 7.86261C4.04657 7.98987 3.9483 8.1419 3.88049 8.30994L0.579494 16.6623C0.447311 16.9978 0.445152 17.3756 0.573448 17.7129C0.701744 18.0501 0.950022 18.3192 1.26384 18.4611C10.376 21.1686 17.8757 24.5496 24.1627 28.7848C24.4757 28.9197 24.8257 28.9179 25.1375 28.7798C25.4492 28.6416 25.6977 28.3782 25.8293 28.0462L29.1303 19.6938C29.2624 19.3583 29.2646 18.9805 29.1363 18.6433C29.008 18.3061 28.7597 18.037 28.4459 17.8951C24.7605 15.6193 20.9473 13.5882 17.028 11.8134Z"
      fill="#40DDFF"
    />
    <path
      d="M15.0124 21.3743C16.8713 21.3743 18.3782 19.7648 18.3782 17.7794C18.3782 15.794 16.8713 14.1845 15.0124 14.1845C13.1536 14.1845 11.6467 15.794 11.6467 17.7794C11.6467 19.7648 13.1536 21.3743 15.0124 21.3743Z"
      fill="#40DDFF"
    />
    <path
      d="M7.7227 12.4541L5.06483 11.2558C4.88874 11.1762 4.74943 11.0252 4.67745 10.836C4.60546 10.6467 4.60668 10.4348 4.68085 10.2465L5.05482 9.30025C5.12938 9.11218 5.27075 8.96337 5.4479 8.88648C5.62506 8.80959 5.82353 8.8109 5.99978 8.89012L8.65765 10.0884C8.83374 10.1681 8.97305 10.319 9.04504 10.5082C9.11703 10.6975 9.11581 10.9095 9.04163 11.0977L8.66766 12.044C8.5931 12.232 8.45174 12.3809 8.27458 12.4577C8.09743 12.5346 7.89895 12.5333 7.7227 12.4541Z"
      fill="#40DDFF"
    />
    <path
      d="M23.71 27.4661L21.0521 26.2678C20.876 26.1882 20.7367 26.0372 20.6647 25.848C20.5927 25.6588 20.594 25.4468 20.6681 25.2585L21.0421 24.3122C21.1167 24.1242 21.258 23.9754 21.4352 23.8985C21.6123 23.8216 21.8108 23.8229 21.9871 23.9021L24.6449 25.1004C24.821 25.1801 24.9603 25.331 25.0323 25.5203C25.1043 25.7095 25.1031 25.9215 25.0289 26.1097L24.6549 27.056C24.5804 27.2441 24.439 27.3929 24.2619 27.4697C24.0847 27.5466 23.8862 27.5453 23.71 27.4661Z"
      fill="#40DDFF"
    />
    <path
      d="M26.3414 28.0592L26.3151 28.0279C21.8386 21.746 15.9472 15.8069 8.30416 9.87112L8.30143 9.86838C8.12949 9.6991 7.99064 9.49516 7.89284 9.26829C7.79504 9.04142 7.74025 8.79611 7.73162 8.54652C7.72299 8.29693 7.76069 8.04799 7.84256 7.81403C7.92443 7.58007 8.04883 7.36573 8.20861 7.18337L14.0352 0.590632C14.357 0.22733 14.8007 0.015199 15.2687 0.000785354C15.7368 -0.0136282 16.1911 0.170848 16.532 0.513743C23.2728 5.72921 29.3507 11.8579 34.613 18.746L34.6154 18.7484C34.9474 19.0931 35.1387 19.5637 35.1479 20.0581C35.1572 20.5526 34.9836 21.031 34.6648 21.3896L28.8382 27.9823C28.5163 28.3456 28.0727 28.5577 27.6046 28.5721C27.1366 28.5866 26.6823 28.4021 26.3414 28.0592Z"
      fill="#252525"
    />
    <path
      d="M26.3414 28.0592L26.3151 28.0279C21.8386 21.746 15.9472 15.8069 8.30416 9.87112L8.30143 9.86838C8.12949 9.6991 7.99064 9.49516 7.89284 9.26829C7.79504 9.04142 7.74025 8.79611 7.73162 8.54652C7.72299 8.29693 7.76069 8.04799 7.84256 7.81403C7.92443 7.58007 8.04883 7.36573 8.20861 7.18337L14.0352 0.590632C14.357 0.22733 14.8007 0.015199 15.2687 0.000785354C15.7368 -0.0136282 16.1911 0.170848 16.532 0.513743C23.2728 5.72921 29.3507 11.8579 34.613 18.746L34.6154 18.7484C34.9474 19.0931 35.1387 19.5637 35.1479 20.0581C35.1572 20.5526 34.9836 21.031 34.6648 21.3896L28.8382 27.9823C28.5163 28.3456 28.0727 28.5577 27.6046 28.5721C27.1366 28.5866 26.6823 28.4021 26.3414 28.0592ZM25.556 9.13488C22.6253 6.16952 19.5085 3.42129 16.2274 0.909346C16.1047 0.781653 15.9595 0.681171 15.8003 0.613705C15.641 0.546238 15.4708 0.513127 15.2996 0.516266C15.1283 0.519405 14.9592 0.558742 14.8023 0.632004C14.6453 0.705266 14.5034 0.811004 14.3849 0.943119L8.55827 7.53584C8.32462 7.80081 8.19893 8.15398 8.20875 8.51783C8.21857 8.88167 8.3631 9.22648 8.61067 9.47663C16.2616 15.4158 22.1814 21.3839 26.6853 27.6994C26.9344 27.9427 27.263 28.0717 27.6003 28.0587C27.9376 28.0457 28.2566 27.8916 28.4885 27.6298L34.3151 21.037C34.5488 20.7721 34.6745 20.4189 34.6647 20.0551C34.6549 19.6912 34.5103 19.3464 34.2627 19.0962C31.5548 15.5879 28.6471 12.2612 25.556 9.13488Z"
      fill="#40DDFF"
    />
    <path
      d="M20.5381 17.3398C22.2797 18.0339 24.2184 17.0887 24.8682 15.2286C25.5181 13.3684 24.6331 11.2978 22.8915 10.6037C21.15 9.9096 19.2113 10.8548 18.5614 12.715C17.9116 14.5751 18.7966 16.6457 20.5381 17.3398Z"
      fill="#40DDFF"
    />
    <path
      d="M16.6282 6.26046L14.5303 4.14531C14.3914 4.00495 14.3103 3.81148 14.3048 3.60733C14.2992 3.40318 14.3698 3.20502 14.5009 3.05635L15.161 2.30944C15.2924 2.16107 15.4736 2.07444 15.6647 2.06856C15.8558 2.06267 16.0414 2.138 16.1806 2.27804L18.2785 4.39319C18.4174 4.53355 18.4985 4.72702 18.504 4.93117C18.5095 5.13533 18.439 5.33349 18.3079 5.48217L17.6478 6.22907C17.5164 6.37744 17.3352 6.46407 17.1441 6.46996C16.9529 6.47584 16.7674 6.4005 16.6282 6.26046Z"
      fill="#40DDFF"
    />
    <path
      d="M26.6929 26.2949L24.5949 24.1798C24.456 24.0394 24.3749 23.8459 24.3694 23.6418C24.3639 23.4376 24.4344 23.2395 24.5655 23.0908L25.2257 22.3439C25.3571 22.1955 25.5382 22.1089 25.7294 22.103C25.9205 22.0971 26.106 22.1724 26.2452 22.3125L28.3431 24.4276C28.482 24.568 28.5632 24.7615 28.5687 24.9656C28.5742 25.1698 28.5036 25.3679 28.3725 25.5166L27.7124 26.2635C27.581 26.4119 27.3999 26.4985 27.2087 26.5044C27.0176 26.5103 26.832 26.4349 26.6929 26.2949Z"
      fill="#40DDFF"
    />
  </svg>
</template>
