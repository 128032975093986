<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 390 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_50_2)">
      <path
        d="M390 21.0461L382.5 18.2466C374.125 15.7582 362.25 12.4993 346 12.0255C329.75 11.5516 308.75 17.8262 292.5 20.3851C276.25 22.9441 260 20.859 243.75 18.8687C227.5 16.8784 211.25 14.7934 195 16.3382C178.75 17.8262 162.5 22.9441 146.25 23.4179C130 23.8918 113.75 19.9112 97.5 16.3382C81.25 12.8031 65.9318 12.6476 49.5 12.6475C29.5 12.6475 13 15.7581 5.5 17.0024L1.14213e-06 17.9355L0 31L8.125 31C16.25 31 32.5 31 48.75 31C65 31 81.25 31 97.5 31C113.75 31 130 31 146.25 31C162.5 31 178.75 31 195 31C211.25 31 227.5 31 243.75 31C260 31 276.25 31 292.5 31C308.75 31 325 31 341.25 31C357.5 31 373.75 31 381.875 31L390 31L390 21.0461Z"
        fill="#16151A"
      />
    </g>
    <defs>
      <clipPath id="clip0_50_2">
        <rect width="390" height="30" fill="white" transform="translate(390 30) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
</template>
