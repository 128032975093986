import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import HomeView from '../views/HomeView.vue';
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue';

declare module 'vue-router' {
  interface RouteMeta {
    title?: string;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Gestão de investimentos',
    },
    component: HomeView,
  },
  {
    path: '/politica-de-privacidade',
    name: 'privacy-policy',
    meta: {
      title: 'Política de privacidade',
    },
    component: PrivacyPolicyView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: savedPosition?.top || 0, behavior: 'smooth' };
  },
});

router.beforeEach((to) => {
  const titlePrefix = 'Finco - ';
  document.title = titlePrefix + (to.meta?.title ?? 'Gestão de investimentos');
});

export default router;
