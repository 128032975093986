
import WavesSvg from '@/assets/svg/WavesSvg.vue';
import WavesReversedSvg from '@/assets/svg/WavesReversedSvg.vue';
import CheckIconSvg from '@/assets/svg/CheckIconSvg.vue';
import AllocationPercentageSvg from '@/assets/svg/AllocationPercentageSvg.vue';
import ProventsCalendarSvg from '@/assets/svg/ProventsCalendarSvg.vue';
import WalletEvolutionSvg from '@/assets/svg/WalletEvolutionSvg.vue';
import WalletProfitSvg from '@/assets/svg/WalletProfitSvg.vue';
import DedicatedSupportSvg from '@/assets/svg/DedicatedSupportSvg.vue';
import FincoModal from '@/components/common/FincoModal.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    WavesSvg,
    WavesReversedSvg,
    CheckIconSvg,
    ProventsCalendarSvg,
    AllocationPercentageSvg,
    WalletEvolutionSvg,
    DedicatedSupportSvg,
    WalletProfitSvg,
    FincoModal,
  },
  data() {
    return {
      showModal: false,
      showModal2: false,
      form: {
        email: '',
      },
    };
  },
  methods: {
    openModal(ctaNumber: number) {
      this.showModal = true;

      fetch('https://o2vhl58esf.execute-api.us-east-1.amazonaws.com/default/lead2', {
        method: 'POST',
        body: JSON.stringify({ modal: ctaNumber, click: new Date() }),
      });
    },
    sendLead() {
      this.showModal = false;
      this.showModal2 = true;

      fetch('https://o2vhl58esf.execute-api.us-east-1.amazonaws.com/default/lead2', {
        method: 'POST',
        body: JSON.stringify(this.form),
      });
    },
  },
  mounted() {
    const hiddenElements = document.querySelectorAll('.hidden-iob');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show-iob');
        }
      });
    });

    hiddenElements.forEach(($el) => observer.observe($el));

    fetch('https://o2vhl58esf.execute-api.us-east-1.amazonaws.com/default/lead', {
      method: 'GET',
    });
  },
});
