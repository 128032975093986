<!-- eslint-disable max-len -->
<template>
  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9_160)">
      <path
        d="M11.5 0.5C5.14913 0.5 0 5.64913 0 12C0 18.3509 5.14913 23.5 11.5 23.5C17.8509 23.5 23 18.3509 23 12C23 5.64913 17.8509 0.5 11.5 0.5ZM12.4583 16.7409V17.75H11.5V16.7936C10.5081 16.7763 9.48175 16.5396 8.625 16.0969L9.06104 14.5214C9.97721 14.8769 11.1972 15.2545 12.1517 15.0389C13.2528 14.7897 13.478 13.657 12.2609 13.1117C11.3687 12.6957 8.64033 12.3402 8.64033 10.0038C8.64033 8.69758 9.63604 7.52842 11.5 7.27254V6.25H12.4583V7.22558C13.1522 7.24379 13.9303 7.36454 14.7986 7.62808L14.4517 9.20646C13.7157 8.94771 12.902 8.71292 12.1105 8.76083C10.6835 8.84421 10.558 10.0795 11.5537 10.597C13.1943 11.3685 15.3333 11.9406 15.3333 13.9962C15.3352 15.6426 14.0463 16.5185 12.4583 16.7409Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_9_160">
        <rect width="23" height="23" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>
