<template>
  <div class="container py-8">
    <h1>Política de Privacidade da FINCO!</h1>
    <p>
      Esta Política de Privacidade descreve como a FINCO! ("nós", "nosso" ou "nos") coleta, usa e
      compartilha informações pessoais quando você usa nosso aplicativo de finanças (o "Serviço").
    </p>
    <h1 class="mt-16">Informações que coletamos</h1>
    <h2>Informações Pessoais</h2>
    <p>
      Ao usar nosso Serviço, podemos solicitar que você nos forneça certas informações de
      identificação pessoal que podem ser usadas para contatá-lo ou identificá-lo ("Informações
      Pessoais"). As informações pessoais podem incluir, mas não estão limitadas a:
    </p>
    <ul>
      <li>Nome</li>
      <li>Endereço de e-mail</li>
      <li>Informações de contato (telefones)</li>
      <li>Cadastro de pessoa física (CPF)</li>
      <li>Data de nascimento</li>
    </ul>
    <h2>Informações de navegação</h2>
    <p>
      Além das Informações Pessoais mencionadas anteriormente, também podemos coletar informações
      sobre como você acessa e usa nosso Serviço ("Informações de Navegação"). Essas informações de
      navegação podem incluir, mas não estão limitadas a:
    </p>
    <ul>
      <li>Endereço de IP (Internet Protocol)</li>
      <li>Tipo de navegador</li>
      <li>Versão do navegador</li>
      <li>Páginas do nosso Serviço que você visita</li>
      <li>Data e hora da sua visita</li>
      <li>Tempo gasto em páginas específicas</li>
      <li>Outras informações de diagnóstico e logs</li>
    </ul>
    <h1 class="mt-16">Como Usamos Suas Informações</h1>
    <p>A FINCO! pode utilizar a sua informação pessoal para as seguintes finalidades:</p>
    <ul>
      <li>
        Para cumprimento de obrigações legais como a emissão de nota fiscal referente aos produtos
        adquiridos em caso de assinantes premium
      </li>
      <li>Cadastro no aplicativo de gestão de investimentos (FINCO APP)</li>
      <li>Para entrar em contato com você para obter feedback ou fornecer suporte</li>
    </ul>
    <h1 class="mt-16">Compartilhamento de Informações</h1>
    <p>Podemos compartilhar suas informações pessoais nas seguintes situações:</p>
    <ul>
      <li>
        Com prestadores de serviços terceirizados que precisam acessar suas informações para
        realizar serviços em nosso nome (por exemplo, processamento de pagamentos)
      </li>
      <li>Para cumprir uma obrigação legal</li>
      <li>Para proteger e defender os direitos ou propriedades da FINCO!</li>
      <li>Para prevenir ou investigar possíveis irregularidades em relação ao Serviço</li>
    </ul>
    <h1 class="mt-16">Como protegemos seus dados</h1>
    <p class="mb-8">
      Ao interagir com qualquer de nossos websites, você (indistintamente denominado “você” ou
      “usuário” neste documento) está ciente da possibilidade de acesso aos seus dados pessoais. Não
      nos responsabilizamos pelo uso de seus dados caso acesse links de terceiros a partir de
      qualquer um dos nossos serviços.
    </p>
    <p class="mb-8">
      A segurança das suas informações pessoais é importante para nós, mas lembre-se de que nenhum
      método de transmissão pela Internet ou método de armazenamento eletrônico é 100% seguro.
      Embora nos esforcemos para usar meios comercialmente aceitáveis para proteger suas informações
      pessoais, não podemos garantir sua segurança absoluta.
    </p>
    <p>
      Nesse sentido, procuramos aqui esclarecer as regras sobre a coleta, o registro, o
      armazenamento, o uso, o compartilhamento e a eliminação dos dados pessoais coletados, dentro
      do escopo dos serviços/produtos oferecidos pela FINCO, de acordo com as leis em vigor, em
      especial a Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados Pessoais”).
    </p>
  </div>
</template>

<style scoped>
h1 {
  font-size: 22px;
  margin-bottom: 12px;
}
h2 {
  font-size: 20px;
  margin-bottom: 12px;
}
p {
  text-align: justify;
}
p,
ul > li {
  color: var(--grey);
}
</style>
