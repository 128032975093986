
import { defineComponent } from 'vue';
import FincoNavbar from '@/components/FincoNavbar.vue'; // @ is an alias to /src
import FincoFooter from '@/components/FincoFooter.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'AppView',
  components: {
    FincoNavbar,
    FincoFooter,
  },
});
