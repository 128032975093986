
import { defineComponent } from 'vue';
// import InstagramIcon from '@/assets/svg/InstagramIcon.vue';
// import FacebookIcon from '@/assets/svg/FacebookIcon.vue';
// import YoutubeIcon from '@/assets/svg/YoutubeIcon.vue';
import { RouterLink } from 'vue-router';

export default defineComponent({
  name: 'FincoNavbar',
  components: {
    // InstagramIcon,
    // FacebookIcon,
    // YoutubeIcon,
    RouterLink,
  },
});
